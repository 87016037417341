import { Box, Flex, Image, useMediaQuery, Input } from '@chakra-ui/react'
import React from 'react'
import Marquee from 'react-fast-marquee'
import { useState } from 'react'
import { BiSearch } from "react-icons/bi"
import { useNavigate, useParams } from 'react-router-dom'
import { motion } from 'framer-motion'
import Hamburger from '../Components/Hamburger'
import HomePhoto from "../assets/home.svg"
import ItemCaro from "../Components/ItemCaro"
import { Helmet } from 'react-helmet'


export default function Furniture() {
    const navigate = useNavigate()
    const [isSmallerThan800] = useMediaQuery('(max-width:800px)')
    const transition = {
        duration: .4, ease: [.43, .13, 0.23, 0.96]
    }
    const params = useParams();
    let categ = params.slug.toString()
    categ = categ[0].toUpperCase() + categ.slice(1);
    const [search, setSearch] = useState(false)
    const [searchTerm, setSearchTerm] = useState("")
    function toggle() {
        setSearch(search => !search)
    }
    function goToHome() {
        navigate('/')
    }

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={transition}>
            <Helmet>
                <title>{categ}</title>
                <meta name="description" content="A luxury furniture brand for all your in-house furniture" />
                <meta name="keywords" content="Furniture, Luxury, Beds, Home Decor" />
            </Helmet>
            <Flex className='navbarSide'>
                <Box cursor={"pointer"} onClick={goToHome}><Image src={HomePhoto} /></Box>
                <Flex mt="1.6rem" mr={isSmallerThan800 ? "4rem" : "0.5rem"} justifyContent={"right"}>
                    <Input type={"text"} onChange={event => { setSearchTerm(event.target.value) }} placeholder="Search for items.." className='searchBox' id="search" name="search" opacity={search ? "1" : "0"} w={search ? "100%" : "0%"} disabled={search ? false : true}>
                    </Input>
                    <BiSearch cursor={"pointer"} fontSize={"1.8rem"} onClick={toggle} />
                <Hamburger />
                </Flex>
            </Flex>
            <Box className='heading'>{categ}</Box>
            <Marquee speed={"50"} gradient="false">
                <Box marginBlock="6rem">
                    <Image src="../images/Solid.png"></Image>
                </Box>
            </Marquee>
            <ItemCaro searchTerm={searchTerm} />
            <Flex marginBlock="6rem" w="94%" marginInline={"auto"} alignItems={"center"} justifyContent={isSmallerThan800 ? "center" : "space-between"}>
                <Image display={isSmallerThan800 ? "none" : "block"} w="10%" h="80px" src="../images/info.png"></Image>
                <Box className='hasText' w={isSmallerThan800 ? "98%" : "35%"}>
                    <Image className="hasText" src="../images/info1.png"></Image>
                    <Box className="imgText"><Box>
                        want to know more about the kind of wood used ?
                    </Box>
                        <Box className='underlined2'>view</Box>
                    </Box>
                </Box>
                <Box className='hasText' w="35%" display={isSmallerThan800 ? "none" : "block"}>
                    <Image className="hasText" src="../images/info2.png"></Image>
                    <Box className="imgText"><Box>
                        interested in behind the scene work ?
                    </Box>
                        <Box className='underlined2'>view</Box>
                    </Box>
                </Box>
                <Image display={isSmallerThan800 ? "none" : "block"} w="10%" h="80px" src="../images/zone.png"></Image>
            </Flex>
        </motion.div>
    )
}