import { Box, Image, useMediaQuery } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { urlForThumbnail } from '../utils/image'

export default function DispCard({ product }){
    const [isSmallerThan800] = useMediaQuery('(max-width:800px)')
    const navigate = useNavigate();
    function handleClick(){
        navigate(`/items/${product.slug.current}`)
    }
    return(
        <Box w={isSmallerThan800 ? "46%" : "28%"} onClick={handleClick} cursor="pointer">
            <Image className="dispImage" src={urlForThumbnail(product.image)} />
            <Box className="name">{product.name}</Box>
        </Box>
    )
}
