import { Box, Flex, Image } from '@chakra-ui/react'
import React from 'react'

export default function ProductDisplay(){

    return(
        <Box className='left gallery'>
            <Flex justifyContent={"space-between"}>
                <Box>
                    <Image w="90%" id="tl" src="../images/topleft.png"/>
                </Box>
                <Box>
                    <Image w="90%" id="tr" src="../images/topright.png"/>
                </Box>
            </Flex>
            <Flex mt="2rem" justifyContent={"space-between"}>
                <Box>
                    <Image w="90%" id="bl" src="../images/botleft.png"/>
                </Box>
                <Box>
                    <Image w="90%"  src="../images/JAIPUR.png"/>
                </Box>
                <Box>
                    <Image w="90%" id="br" src="../images/botright.png"/>
                </Box>
            </Flex>
        </Box>
    )
}