import ImageUrlBuilder from "@sanity/image-url";
import client from "./client";

function urlForThumbnail(source) {
    return ImageUrlBuilder(client).image(source).width(300).height(300).url();
}
function urlForDetail(source) {
    return ImageUrlBuilder(client).image(source).width(900).height(500).auto('format')
        .fit('max')
        .width(720).url();
}

export { urlForThumbnail };
export { urlForDetail };  