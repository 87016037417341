import React from 'react'
import Home from "../Pages/Home"
import Furniture from "../Pages/Furniture"
import Product from "../Pages/Product"
import Detail from "../Pages/Detail"
import { AnimatePresence } from "framer-motion"
import { Route, Routes, useLocation } from 'react-router-dom'

function AnimatedRoutes() {
    const location = useLocation()

    return (
        <AnimatePresence initial={false} mode="wait">
            <Routes location = {location} key = {location.pathname}>
                <Route path="/" element={<Home />} />
                <Route path="/:slug" element={<Furniture />} />
                <Route path="/items/:slug" element={<Product />} />
                <Route path="/product/:slug" element={<Detail />} />
                <Route path="*" element={<Home />} />
            </Routes>
        </AnimatePresence>
    )
}

export default AnimatedRoutes