import {
    Box, Checkbox, filter, Slider, SliderMark, useMediaQuery, VStack
} from '@chakra-ui/react'
import React, { useRef } from 'react'
import {
    RangeSlider,
    RangeSliderTrack,
    RangeSliderFilledTrack,
    RangeSliderThumb,
} from '@chakra-ui/react'

import { useEffect } from 'react';
import { useState } from 'react';


export default function Filter(props) {
    let products = props.products;
    let max = props.max;
    let filterBox = useRef(null)
    let open = props.open;
    let min = props.min;
    const [isSmallerThan800] = useMediaQuery('(max-width:800px)')
    const [finish, setFinish] = useState([])
    const [size, setSize] = useState([])
    useEffect(() => {
        products.forEach(product => {
            setFinish(current => [...current, product.finish]);
            setSize(current => [...current, product.size]);
        });
    }, [products])
    useEffect(()=>{
        if(isSmallerThan800){
            if(open){
                filterBox.style.width = "100vw";
                filterBox.style.transform = "translateX(0)"
            }
            else{
                filterBox.style.width = "0";
                filterBox.style.transform = "translateX(-100vw)"
            }
        }
    })

    const filters = finish.filter((v, i, a) => a.indexOf(v) === i).map(size => {
        return <Checkbox onChange={props.handleChange} colorScheme="blackAlpha" value={size}>{size}</Checkbox>
    })
    const sizeFilters = size.filter((v, i, a) => a.indexOf(v) === i).map(size => {
        return <Checkbox onChange={props.handleChange} colorScheme="blackAlpha" value={size}>{size}</Checkbox>
    })
    return (
        <VStack ref={el => filterBox = el} className='filters' alignItems={"baseline"}>
            <VStack alignItems={"stretch"} w="50%">
                <Box marginTop="4rem" fontWeight={800}>Finish</Box>
                {filters}
            </VStack>
            {/* <VStack alignItems={"stretch"} w="50%">
                <Box marginTop="3rem" fontWeight={800}>Wood</Box>
                <Checkbox title="Wood name 1" />
                <Checkbox title="Wood name 2" />
                <Checkbox title="Wood name 3" />
            </VStack> */}
            {max > min && <VStack alignItems={"stretch"} w="50%">
            <Box marginTop="3rem" fontWeight={800}>Price Range</Box>
                 <Slider><RangeSlider onChange={props.handleSlider} aria-label={['min', 'max']} defaultValue={[min, max]} min={min} max={max}>
                    <SliderMark value={0} mt='4' ml='-3' fontSize='sm'>
                        {min}
                    </SliderMark>
                    <RangeSliderTrack bg='blackAlpha.300'>
                        <RangeSliderFilledTrack bg='black' />
                    </RangeSliderTrack>

                    <RangeSliderThumb boxSize={4} index={0}>
                        <Box color='green' />
                    </RangeSliderThumb>
                    <RangeSliderThumb boxSize={4} index={1}>
                        <Box color='green' />
                    </RangeSliderThumb>
                    <SliderMark value={100} mt='4' ml='-3' fontSize='sm'>
                        {max}
                    </SliderMark>
                </RangeSlider>
                </Slider>
            </VStack>}

            {/* <VStack alignItems={"initial"} w="50%">
                <Box marginTop="3rem" fontWeight={800}>Storage</Box>
                <Checkbox title="Box" />
                <Checkbox title="None" />
            </VStack> */}
            <VStack alignItems={"stretch"} w="50%">
                <Box marginTop="3rem" fontWeight={800}>Size</Box>
                {sizeFilters}
            </VStack>
        </VStack>
    )
}
