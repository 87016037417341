import React from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { Helmet } from "react-helmet"
import AnimatedRoutes from "./Components/AnimatedRoutes"
function App() {

  return (
    <BrowserRouter>
      <Helmet>
        <title>Taru</title>
        <meta name="description" content="A luxury furniture brand for all your in-house furniture" />
        <meta name="keywords" content="Furniture, Luxury, Beds, Home Decor" />
      </Helmet>
        <AnimatedRoutes/>
    </BrowserRouter>
  );
}

export default App;
