import { Box, Image, Flex, useMediaQuery } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import { urlForThumbnail } from '../utils/image'

export default function SellCard({ product }) {
    const transition = {
        duration: .4, ease: [.43,.13,0.23, 0.96]
    }
    const navigate = useNavigate();
    function change() {
        navigate(`/product/${product.slug.current}`)
    }
    return (
        <motion.div className='sellCard' whileHover={{scale: 1.05}} transition={transition} onClick={change}>
            <Image className="dispImage" src={urlForThumbnail(product.image)} />
            <Box className="sellName">{product.name}</Box>
            <Box className="subInfo">{product.size} | {product.finish}</Box>
            <Flex>
                <Box className='price'>Rs. {product.price}</Box>

            </Flex>
        </motion.div>
    )
}