import { Box, Flex, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import DispCard from './DispCard'
import client from '../utils/client';
import { useParams } from 'react-router-dom';


export default function ItemCaro(props){
    let searchTerm = props.searchTerm;
    const params = useParams();
    let categ = params.slug.toString()
    categ = categ[0].toUpperCase() + categ.slice(1);

    const [products, setProducts] =useState(null)
    // console.log(products);
    // console.log(searchTerm);
    useEffect(() => {
        const fetchData = async () => {
            try {
                let products = await client.fetch(`*[_type == "category" && domain =="${categ}"]`)
                if(categ=="Collections"){
                    products = await client.fetch(`*[_type == "collections"]`)                                  
                }
                setProducts(products)
            } catch (err) {
                alert(err)
            }
        };
        fetchData();
    }, [])
    let cards;
    if(products){
        cards = products.filter((product)=>{
            if(searchTerm == ""){
                return product
            }
            else if(product.name.toLowerCase().includes(searchTerm.toLowerCase())){
                return product
            }
        }).map(product => {
            return <DispCard product={product} key={product.slug}/>
        })
    }

    return(
     <Flex className="items">
        {cards}
    </Flex>
    )
}
