import { Box, Flex, Image, VStack } from '@chakra-ui/react'
import React from 'react'
import Navbar from '../Components/Navbar'
import Hero from "../Components/Hero"
import Marquee from 'react-fast-marquee'
import gsap, { CSSPlugin, Power3 } from 'gsap'
import { useEffect } from 'react'
import ProductDisplay from '../Components/ProductDisplay'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { CSSRulePlugin } from 'gsap/all'
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import HomeCaro from '../Components/HomeCaro'
import Hamburger from '../Components/Hamburger'

// gsap.registerPlugin(ScrollTrigger)
export default function Home() {
    gsap.registerPlugin(CSSPlugin, CSSRulePlugin)

    const left = gsap.utils.toArray('.left')
    const right = gsap.utils.toArray('.right')
    const revealText = gsap.utils.toArray('.revealText')

    function scrolls() {
        left.forEach(box => {
            let tl = gsap.timeline({
                scrollTrigger: {
                    trigger: box,
                    // scrub: true,
                    start: "center bottom"
                }
            })
            tl.to(box, { x: 0, opacity: 1, duration: 1.5, ease: Power3.easeOut })
        });
        right.forEach(box => {
            let tl = gsap.timeline({
                scrollTrigger: {
                    trigger: box,
                    // scrub: true,
                    start: "center bottom"
                }
            })
            tl.to(box, { x: 0, opacity: 1, duration: 1.5, ease: Power3.easeOut })
        });
        revealText.forEach(box => {
            let tl = gsap.timeline({
                scrollTrigger: {
                    trigger: box,
                    // scrub: true,
                    start: "center bottom"
                }
            })
            tl.to(box, { y: 0, opacity: 1, duration: 1.5, ease: Power3.easeOut }, "+0.4")
        });
    }

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger)
        window.pageYOffset = 0;
    }, [])
    useEffect(() => {
        scrolls();
    })






    return (
        <Box position={"relative"} overflowX="hidden">
            <Navbar />
            <Hero />
            <Flex className='furn'>
                <Image className='furnImg left' src="../images/window.png"></Image>
                <VStack id="furnText" className='right' gap="4rem">
                    <Box id='furnHead' className="revealText">
                        Furniture, that Stays with Your Family for Generations
                    </Box>
                    <Box className="text revealText">
                        Owning a durable piece of furniture for your home is the best feeling. Our furniture store sells timeless, attractive, and functional pieces.
                        Our furniture is handpicked from the finest materials and handcrafted by expert artisans.
                        Our innovative and contemporary designs will bring elegance to any room, along with our timeless, traditional pieces, and can withstand daily use still looking great in 20 years.
                        We believe that investing in high-quality furniture is not just about the immediate joy of having a beautiful piece in your home, but also about the peace of mind that comes from knowing it will be a beloved part of your family's history for years to come.
                    </Box>
                    <Box className="link revealText"><Box display="inline" borderBottom={"1px solid black"}>explore furniture </Box><Image display={"inline"} src="../images/arrowRight.png" w="0.8rem" ml="0.5rem" /></Box>
                </VStack>
            </Flex>
            <Flex position={"relative"} className='caroPara revealCont' mt="10rem">
                <VStack id="caroText" className="left" gap="4rem">
                    <Box id='caroHead' className="revealText">
                        Modern, Classy, & <br />
                        Chic Furniture for Your Home.
                    </Box>
                    <Box className="text revealText">
                        At TARU you'll find modern, elegant, and stylish home furnishings. We want to help you design a beautiful and functional home that reflects your style.
                        Our furniture is carefully selected from the current styles and trends, making it easy to match your decor. You'll find a modern sofa, exquisite dining table, and interesting accent chair here.
                        Our furniture is a wonderful blend of simplicity, functionality, and elegance. Clean design, neutral colour palettes, and high-quality materials make our collection ideal.
                        We construct durable furniture from solid wood and metal making everyday furniture use beautiful, comfy, and functional.
                    </Box>
                    <Box className="link revealText"><Box display="inline" borderBottom={"1px solid black"}>explore modern furniture </Box><Image display={"inline"} src="../images/arrowRight.png" w="0.8rem" ml="0.5rem" /></Box>
                </VStack>
                {/* infiniteLoop={"true"} */}
                <Carousel className='right caroRoot' width={"100%"} autoPlay={"true"} interval="3000" showArrows={false} showStatus={false}>
                    <HomeCaro />
                    <HomeCaro />
                </Carousel>
            </Flex>
            <Marquee speed={"50"} gradient="false">
                <Box marginBlock="6rem">
                    <Image src="../images/women.png"></Image>
                </Box>
            </Marquee>
            <Flex className='pot revealCont'>
                <ProductDisplay />
                <VStack id="potText" className='right' gap="4rem">
                    <Box id='potHead' className="revealText">
                        Blue Pottery, Candles, and Home Decor <br />
                        Elevate Your Space with Our Premium Collection
                    </Box>
                    <Box className="text revealText">
                        Minor details may make a significant impact in the design and feel of your home hence we have a variety of blue pottery, candles, and home accessories to help you personalise your environment.
                        Our blue pottery is created by expert artisans utilising generations-old traditions. Its elaborate patterns and unusual blue colour make it a great accent to any décor.
                        Our professionally picked home decor assortment helps you create a coherent design and reflect your personal style.
                        Visit our store to explore how our blue pottery, candles, and home decor can make your room distinctive.

                    </Box>
                    <Box className="link revealText"><Box display="inline" borderBottom={"1px solid black"}>explore home decor </Box><Image display={"inline"} src="../images/arrowRight.png" w="0.8rem" ml="0.5rem" /></Box>
                </VStack>
            </Flex>
        </Box>
    )
}