import { Box, Flex, Image, VStack, Text, ListIcon, List, ListItem, Button } from '@chakra-ui/react'
import React, { useEffect, useState, } from 'react'
import { BiHeart, BiRupee } from 'react-icons/bi'
import { BsDot } from "react-icons/bs"
import { useNavigate, useParams } from 'react-router-dom'
import client from '../utils/client'
import { urlForDetail, urlForThumbnail } from '../utils/image'
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Helmet } from 'react-helmet'
import { motion } from "framer-motion"
export default function Detail() {
    const params = useParams();
    const navigate = useNavigate();
    const [state, setState] = useState(null)
    const transition = {
        duration: .4, ease: [.43, .13, 0.23, 0.96]
    }
    const transition2 = {
        duration: .4, ease: [.43, .13, 0.23, 0.96], delay: 0.3
    }
    useEffect(() => {
        const fetchData = async () => {
            try {
                const products = await client.fetch(`*[slug.current == "${params.slug}"]`)
                // console.log(products[0]);
                setState(products[0])
            } catch (err) {
                alert(err)
            }
        };
        fetchData();
    }, [])

    function goToCollection(){
        state.collections ? navigate(`/items/${state.collections}`) : navigate(`/collection`)
    }

    return (
        state && <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={transition}>
            <Helmet>
                <title>{state.name}</title>
                <meta name="description" content="A luxury furniture brand for all your in-house furniture" />
            </Helmet>
            <Flex className='productMain'>
                <VStack width={"90%"} className="phoneOnly">
                    <Flex className="phoneMoneyCont" alignItems={"center"} justifyContent="space-between" w="90%">
                        <Flex className="deskMoney" alignItems={"center"}>
                            <BiRupee />
                            <Box display={"inline"}>{state.price}</Box>
                        </Flex>
                    </Flex>
                    {state.description && <Box className='phoneText'>
                        {state.description}
                    </Box>}
                </VStack>
                <motion.div initial={{x: -100, opacity: 0}} animate={{opacity: 1, x:0}} transition={transition2} className='prodImg'>
                    <Carousel showThumbs={false} autoPlay={"true"} interval="3000" showArrows={false} infiniteLoop={"true"} showStatus={false}>
                        <Image src={urlForDetail(state.image)}></Image>
                        <Image src={urlForDetail(state.image)}></Image>
                        <Image src={urlForDetail(state.image)}></Image>
                    </Carousel>
                </motion.div>
                <VStack id="prodText" gap="4rem">
                    <motion.div initial={{ opacity: 0, y: 100 }} animate={{ opacity: 1, y: 0 }} transition={transition2} id='prodHead'>
                        {state.name}
                    </motion.div>
                    {state.description && <motion.div initial={{ opacity: 0, y: 100 }} animate={{ opacity: 1, y: 0 }} transition={transition2} className='desktopText'>
                        {state.description}
                    </motion.div>}
                    <Flex className="deskMoneyCont" alignItems={"center"} justifyContent="space-between" w="90%">
                        <Flex className="deskMoney" alignItems={"center"}>
                            <BiRupee />
                            <Box display={"inline"}>{state.price}</Box>
                        </Flex>
                    </Flex>
                </VStack>
            </Flex>
            <Flex className='details'>
                <VStack id="deets" gap="4rem">
                    <Flex className='specs'>
                        {state.head1 && <Box>
                            <Text className='deetHead'>{state.head1}</Text>
                            <Text>{state.dimension}</Text>
                            <Text>{state.dimension2}</Text>
                        </Box>}
                        <Box>
                            <Text className='deetHead'>{state.head2}</Text>
                            <Text>{state.material}</Text>
                        </Box>
                    </Flex>
                    {state.head3 && <Box className='info'>
                        <Box className='deetHead'>{state.head3}</Box>
                        <List spacing={3} w="85%">
                            <ListItem>
                                <ListIcon as={BsDot} color='' />
                                {state.feature1}
                            </ListItem>
                            <ListItem>
                                <ListIcon as={BsDot} color='' />
                                {state.feature2}
                            </ListItem>
                        </List>
                    </Box>}
                    {state.precaution1 && <Box className='info'>
                        <Box className='deetHead'>PRE-CAUTIONS</Box>
                        <List spacing={3} w="85%">
                            <ListItem>
                                <ListIcon as={BsDot} color='' />
                                {state.precaution1}
                            </ListItem>
                            <ListItem>
                                <ListIcon as={BsDot} color='' />
                                {state.precaution2}
                            </ListItem>
                        </List>
                    </Box>}
                    <Box className="link"><Box onClick={goToCollection} display="inline" borderBottom={"1px solid black"}>{state.collections ? "view collection" : "view other collections"} </Box><Image display={"inline"} src="../images/arrowRight.png" w="0.8rem" ml="0.5rem" /></Box>

                </VStack>
                {state.image2 && <Image className='detailImg' src={urlForThumbnail(state.image2)}></Image>}
            </Flex>
            <Flex className='prodFooter'>
                <Box>
                    Get product customized as per your needs.
                </Box>
                <Button color="white" backgroundColor="black" borderRadius="20px" paddingInline="25px" alignItems={"center"} fontSize={"0.8rem"}>Proceed</Button>
            </Flex>
        </motion.div>
    )
}