import { extendTheme } from '@chakra-ui/react'
import "@fontsource/inter"
import "@fontsource/open-sans"
import "@fontsource/roboto-condensed"
import "@fontsource/rubik"
import "@fontsource/playfair-display"

const theme = extendTheme({
  fonts: {
    // heading: `'EB Garamond', serif`,
    body: "Gangster"      
  },
})

export default theme;