import React, { useEffect, useRef } from 'react'
import { Box, Image, Text, useMediaQuery, VStack } from "@chakra-ui/react"
import CSSRulePlugin from 'gsap/CSSRulePlugin'
import { Timeline } from 'gsap/gsap-core'
import gsap, { Power2 } from 'gsap'


export default function Home() {
    const [isSmallerThan800] = useMediaQuery('(max-width:800px)')
    let container = useRef(null)
    let image = useRef(null)
    let text = useRef(null)
    let imageReveal = CSSRulePlugin.getRule('.reveal:after')

    const tl = new Timeline()

    useEffect(() => {
        gsap.registerPlugin(Timeline)
        tl.to(container, { duration: 0, visibility: "visible" })
            .to(
                imageReveal,
                {
                    duration: 1.4, width: "0%", ease: Power2.easeInOut
                }, "+0.8")
            .from(image, {
                duration: 1.4,
                scale: 1.6,
                ease: Power2.easeInOut,
                delay: -1.4
            })
        // })
    })
    return (

        <VStack className="hero" minH={"50vh"}>
            <Box pos="relative" ref={el => container = el} className='taruImg reveal'>
                <Image ref={el => image = el} className="heroImg" src="../images/hero.png" />
                <Text position={"absolute"} fontFamily="Playfair Display" fontSize={isSmallerThan800 ? "3.5rem" : "9rem"} letterSpacing="0.9rem" fontWeight="900" color="white">TARU</Text>
            </Box>
            <Box ref={el => text = el} id='intro' className="revealText">
                we do not sell furniture <br />
                we sell <Text className="underlined">experience</Text>
            </Box>
        </VStack>
    )
}