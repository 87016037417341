import React, { useState } from 'react'
import { Flex, Box, useMediaQuery, Input, Image } from "@chakra-ui/react"
import { useNavigate } from 'react-router-dom';
import Hamburger from './Hamburger';
import { BiSearch } from 'react-icons/bi';
import HomePhoto from "../assets/home.svg"
import AsyncSelect from "react-select/async"
import client from '../utils/client';
export default function Navbar() {


    const [isSmallerThan800] = useMediaQuery('(max-width:800px)')
    const navigate = useNavigate();
    const [search, setSearch] = useState(false)
    const [option, setOption] = useState({})
    const [value, setValue] = useState('')
    function clickFurn() {
        navigate('/furniture')
    }
    function clickHome() {
        navigate('/homedecor')
    }
    function clickMatt() {
        navigate('/mattress')
    }
    function toggle() {
        setSearch((prevSearch) => !prevSearch)
    }
    const fetchData = (inputValue, callback) => {
        if (!inputValue) {
            callback([]);
        }
        else {
            setTimeout(() => {
                // console.log(`https://yearbook-portal-backend-2022.herokuapp.com/searchUsers?name=${inputValue}`)
                const fetch = async () => {
                    try{
                        const products = await client.fetch(`*[_type == "product" && lower(name) match lower("${inputValue}") || _type == "category" && lower(name) match lower("${inputValue}")]`)
                        let tempArray = [];
                        products.forEach(element => {
                             if(element.category){
                                tempArray.push({ label: `${element.name}`, value: `/product/${element.slug.current}`});
                             }
                             else{
                                 tempArray.push({ label: `${element.name}`, value: `/items/${element.slug.current}`});
                             }
                        });
                        callback(tempArray);
                    }
                    catch(err){
                        console.log(err);
                    }
                }
                fetch();
            });
        }
    }
    const onSearchChange = (option) => {
        if (option) {
            setOption({ option })
            navigate(`${option.value}`)
        }
    }


    return (
        <Flex className='navbar'>
            {isSmallerThan800 ? "" : <Box><Image src={HomePhoto}/></Box>}
            <Flex className='options'>
                <Box onClick={clickFurn} className='option'>FURNITURE</Box>
                <Box onClick={clickHome} className='option'>HOME DECOR</Box>
                <Box onClick={clickMatt} className='option'>MATTRESS</Box>
            </Flex>
            <Flex mt={isSmallerThan800 ? "1.5rem" : "1rem"} w={isSmallerThan800 ? "75%" : "28%"} mr={isSmallerThan800 ? "4rem" : "1rem"} justifyContent={isSmallerThan800 ? "space-between" : "right"}>
            {isSmallerThan800 ? <Box mt="-1.5rem"><Image src={HomePhoto}/></Box> : "" }
                <Box display={search ? "block" : "none"} mr="0.8rem">
                    <Flex alignItems="center" w="100%">
                        <AsyncSelect
                            value={option}
                            loadOptions={fetchData}
                            placeholder="search"
                            className="selectProf" classNamePrefix="selectOpt"
                            onChange={(e) => {
                                onSearchChange(e)
                            }}
                            defaultOptions={false} />
                    </Flex>
                </Box>
                <BiSearch cursor={"pointer"} fontSize={"1.8rem"} onClick={toggle} />
                <Hamburger />
            </Flex>

        </Flex>
    )
}