import React from 'react'
import { Box, Flex, useMediaQuery, VStack } from '@chakra-ui/react';
import gsap, { Power4 } from 'gsap';
import { Timeline } from 'gsap/gsap-core';
import { useRef } from 'react';
export default function Hamburger() {
    const [isSmallerThan800] = useMediaQuery('(max-width:800px)')
    const [showMenu, setShowMenu] = React.useState(false)
    // const menuItem = useRef(null)
    // let menuItems = gsap.utils.toArray('.menuItems')
    let menu1 = useRef(null)
    let menu2 = useRef(null)
    let menu3 = useRef(null)
    let menu4 = useRef(null)
    const staggerText = (node1, node2, node3, node4) =>{
        gsap.to([node1, node2, node3, node4], {duration: 0.8, y: 0, opacity: 1, delay : 0.1 ,stagger: {amount: 0.3}, ease: Power4.easeInOut})
    }
    const removeText = (node1, node2, node3, node4) =>{
        gsap.to([node1, node2, node3, node4], {duration: 0.8, y: 100, opacity: 0, delay : 0.1 ,stagger: {amount: 0.3}, ease: Power4.easeInOut})
    }
    
    function toggleMenu() {
        setShowMenu(showMenu => !showMenu); 
        document.body.classList.toggle('lock-scroll');
        staggerText(menu1, menu2, menu3, menu4)
        if(showMenu){
            removeText(menu1,menu2,menu3,menu4)
        }
        // tl.staggerFrom('.menuItem', 1.5, {y:"100%", opacity: 1, ease: Power4.easeOut }, 0.15, "+1.4")
    }
    return (
        <Box zIndex={10}>
            <Box className='hamContainer' >
                <Box className='hamBox' onClick={toggleMenu}>
                    <Box className='ham' transform={showMenu ? "rotate(45deg)" : ""}>
                    </Box>
                    <Box className='ham-2' transform={showMenu ? "rotate(-45deg) translate(4px, -5px)" : ""}>
                    </Box>
                </Box>
            </Box>
            <Box className="menu" transform={showMenu ? "translateX(0);" : "translateX(100vw);"}>
                <VStack alignItems={"baseline"} className="menuVStack">
                    <Box className = "menuItem" ref={el => menu1 = el}>ABOUT US</Box>
                    <Box className = "menuItem" ref={el => menu2 = el}>PROJECTS</Box>
                    <Box className = "menuItem" ref={el => menu3 = el}>FAQ'S</Box>
                </VStack>
                <Flex className='hamFoot'>
                    <Box>
                        <Flex className='socials'>
                            <Box>FACEBOOK</Box>
                            <Box>INSTAGRAM</Box>
                            <Box>EMAIL</Box>
                        </Flex>
                        <Flex className='numbers'>
                            <Box>+(91) 9001946270 </Box>
                            <Box>+(91) 9829881881</Box>
                        </Flex>
                    </Box>
                    <Box className='footName menuItem' ref={el => menu4 = el}>Taru.</Box>
                </Flex>
            </Box>
        </Box >
    )
}