import { Box, Flex, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import client from '../utils/client';
import SellCard from './SellCard'

export default function SellCaro(props) {
    let searchTerm = props.searchTerm;
    const products = props.products;

    const filters = props.filters;
    let val = props.val;
    // console.log(val);

    const cards = products.filter((product) => {
        if (searchTerm == "") {
            return product
        }
        else if (product.name.toLowerCase().includes(searchTerm.toLowerCase())) {
            return product
        }
    }).filter((product) => {
        if (filters.length === 0) {
            return product
        }
        else {
            const BreakError = {};
            try {
                let flag = true;
                filters.forEach(filter => {
                    flag = false;
                    if (product.finish == undefined || product.size == undefined) {
                        throw BreakError;
                    }
                    if (product.finish.toLowerCase().includes(filter.toLowerCase()) || product.size.toLowerCase().includes(filter.toLowerCase())) {
                        flag = true;
                    }
                });
                if (flag) {
                    flag = false;
                    return product
                }
            } catch(err) {
                if (err !== BreakError) throw err;
            }
        }
    }).filter((product) => {
        if (val[0] === 0 || val[1] === 0) {
            return product
        }
        else if (product.price >= val[0] && product.price <= val[1]) {
            return product
        }
    }).map(product => {
        return <SellCard product={product} key={product.slug} />
    })

    return (
        products && <Flex className="sellItems">
            {cards}
        </Flex>
    )
}