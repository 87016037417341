import { Box, Flex, Image, Input, useMediaQuery } from '@chakra-ui/react'
import Marquee from 'react-fast-marquee'
import SellCaro from '../Components/SellCaro'
import Filter from '../Components/Filter'
import Hamburger from '../Components/Hamburger'
import { useNavigate, useParams } from 'react-router-dom'
import HomePhoto from "../assets/home.svg"
import { useEffect, useRef, useState } from 'react'
import client from '../utils/client'
import { BiSearch } from 'react-icons/bi'
import { Helmet } from 'react-helmet'
import { motion } from 'framer-motion'
import { FiFilter, FiX } from 'react-icons/fi'
export default function Product() {
  const [isSmallerThan800] = useMediaQuery('(max-width:800px)')
  const params = useParams();
  let categ = params.slug.toString()
  const navigate = useNavigate()
  categ = categ[0].toUpperCase() + categ.slice(1);
  const [state, setState] = useState({
    products: [],
    error: '',
    loading: true
  });
  const transition = {
    duration: .4, ease: [.43, .13, 0.23, 0.96]
  }
  const { loading, error, products } = state;
  const [search, setSearch] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")
  const [filters, setFilters] = useState([])
  const [open, setOpen] = useState(false)
  const [max, setMax] = useState(0)
  let sellCaro = useRef(null)
  const [min, setMin] = useState(0)
  const [val, setVal] = useState([0, 0])
  function toggle() {
    setSearch(search => !search)
  }
  // console.log(categ);

  function minmax() {
    let temparray = []
    const len = products.length;
    products.forEach(product => {
      temparray.push(product.price)
    });
    if (temparray.length == len) {
      const sort = temparray.sort((a, b) => a-b)
      setMin(sort[0])
      setMax(sort[len - 1])
    }

  }
  useEffect(() => {
    minmax();
  }, [products])
  // console.log(min,max);  
  useEffect(() => {
    setVal([min, max])
  }, [min, max])
  useEffect(() => {
    const fetchData = async () => {
      try {
        let products = await client.fetch(`*[_type == "product" && category =="${categ}" || _type == "product" && collections =="${categ}"]`)
        // if(products.length == 0){
        //     products = await client.fetch(`*[_type == "product"]`)                                  
        // }
        setState({ products: products, loading: false })
        setMin(products[0].price)
        setMax(products[0].price)
      } catch (err) {
        setState({ loading: false, error: err.message })
      }
    };
    fetchData();
  }, [])
  useEffect(() => {
    if (isSmallerThan800) {
      if (open) {
        sellCaro.style.display = "none"
      }
      else {
        sellCaro.style.display = "flex"
      }
    }
  })

  function handleChange(e) {
    if (e.target.checked) {
      setFilters((prevFilters) => {
        return (
          [...prevFilters, e.target.value]
        )
      })
    }
    else {
      setFilters(filters.filter(item => item !== e.target.value));
    }
  }
  function handleSlider(e) {
    setVal(e)
  }
  function openFilters() {
    setOpen((prevOpen) => !prevOpen)
  }
  function goToHome() {
    navigate('/')
  }


  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={transition}>
      <Helmet>
        <title>{categ}</title>
        <meta name="description" content="A luxury furniture brand for all your in-house furniture" />
        <meta name="keywords" content="Furniture, Luxury, Beds, Home Decor" />
      </Helmet>
      <Flex className='navbarSide'>
        <Box cursor={"pointer"} onClick={goToHome}><Image src={HomePhoto} /></Box>
        <Flex mt="1.7rem" mr={isSmallerThan800 ? "4rem" : "0.5rem"} justifyContent={"right"}>
          <Input type={"text"} onChange={event => { setSearchTerm(event.target.value) }} placeholder="Search for items.." className='searchBox' id="search" name="search" opacity={search ? "1" : "0"} w={search ? "100%" : "0%"} disabled={search ? false : true}>
          </Input>
          <BiSearch cursor={"pointer"} fontSize={"1.8rem"} onClick={toggle} />
          <Hamburger />
        </Flex>
      </Flex>
      <Box className='heading'>{categ || products[0].category}</Box>
      <Marquee speed={"50"} gradient="false">
        <Box marginBlock="6rem">
          <Image src="../images/Solid.png"></Image>
        </Box>
      </Marquee>
      {isSmallerThan800 ? <Flex justifyContent={"right"} mr="2.5rem" onClick={openFilters} className='filterButton' alignItems={"center"}><Box mr="0.5rem">filters</Box> {open ? <FiX /> : <FiFilter />} </Flex> : ""}
      <Flex className="itemDisplay">
        <Filter open={open} max={max} min={min} handleSlider={handleSlider} handleChange={handleChange} products={products} />
        <Box ref={el => sellCaro = el} w={isSmallerThan800 ? "100%" : "75%"}>
          <SellCaro searchTerm={searchTerm} products={products} val={val} filters={filters}
          />
        </Box>
      </Flex>
    </motion.div>
  )
}