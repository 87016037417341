import { Box, Flex, Image, useMediaQuery, VStack } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import gsap, { CSSPlugin, Power3 } from "gsap"
import { CSSRulePlugin } from 'gsap/all';


function HomeCaro() {
    const [isSmallerThan800] = useMediaQuery('(max-width:800px)')
    gsap.registerPlugin(CSSPlugin, CSSRulePlugin)

    const left = gsap.utils.toArray('.left')
    const right = gsap.utils.toArray('.right')
    const revealText = gsap.utils.toArray('.revealText')
    function scrolls() {
        left.forEach(box => {
            let tl = gsap.timeline({
                scrollTrigger: {
                    trigger: box,
                    // scrub: true,
                    start: "center bottom"
                }
            })
            tl.to(box, { x: 0, opacity: 1, duration: 1.5, ease: Power3.easeOut })
        });
        right.forEach(box => {
            let tl = gsap.timeline({
                scrollTrigger: {
                    trigger: box,
                    // scrub: true,
                    start: "center bottom"
                }
            })
            tl.to(box, { x: 0, opacity: 1, duration: 1.5, ease: Power3.easeOut })
        });
        revealText.forEach(box => {
            let tl = gsap.timeline({
                scrollTrigger: {
                    trigger: box,
                    // scrub: true,
                    start: "center bottom"
                }
            })
            tl.to(box, { y: 0, opacity: 1, duration: 1.5, ease: Power3.easeOut }, "+0.4")
        });
    }

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger)
        window.pageYOffset = 0;
    }, [])
    useEffect(() => {
        scrolls();
    })

    return (
        <Box w="100%">
            <Box position={"absolute"} className="bg right"></Box>
            <Box position={"relative"} backgroundImage={'url("../images/caro1Bg.png")'} backgroundSize="contain" backgroundRepeat={"no-repeat"} backgroundPosition="center" className='caroImg right'>
                {/* <Image className="caroPhoto" src="../images/landChair.png"></Image> */}
                <VStack pt="2rem" gap="0.2rem" alignItems={"baseline"} pl={isSmallerThan800 ? "1rem" : "2rem"}>
                    <Box pt="2rem" color="white" fontSize={isSmallerThan800 ? "0.9rem" : "1.5rem"} fontWeight="700">STUDIO CHAIR</Box>
                    {isSmallerThan800 ? " " : <Box color="#CCCC" w="45%" textAlign="left" fontFamily="Inter" fontWeight={"light"}>lorem ipsum dolor sit amet, <br /> consectetur adipiscing elit.</Box>}
                    <Box className='colorBox'>
                        <Flex>
                            <Box backgroundColor={"#000"} className='color'></Box>
                            <Box backgroundColor={"#3870FF"} className='color'></Box>
                        </Flex>
                        <Flex>
                            <Box backgroundColor={"#FFC700"} className='color'></Box>
                            <Box backgroundColor={"#79AC2B"} className='color'></Box>
                        </Flex>
                    </Box>
                </VStack>
            </Box>
        </Box>
    )
}

export default HomeCaro